import { TextInput, Select, Form, Loading } from 'library';
import React, { Component } from 'react';
import {ajax_wrapper} from 'functions';
import { Savings} from 'pages';

export default class Retiree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            data: {},
            form_submission: null,
            loaded: true,
            state: null,
        }

        this.next_step = this.next_step.bind(this);
        this.prev_step = this.prev_step.bind(this);
        this.submit = this.submit.bind(this);
        this.submit_callback = this.submit_callback.bind(this);
        this.final_submit = this.final_submit.bind(this);
        this.login_callback = this.login_callback.bind(this);

        this.find_location = this.find_location.bind(this);
        this.find_location_callback = this.find_location_callback.bind(this);
    }

    componentDidMount() {
        this.find_location();
        console.log("Mount")
    }

    find_location() {
        console.log("Sending Find Location")
        let url = 'https://api.ipgeolocation.io/ipgeo?apiKey=7852dde0bf2245f6aeafc1cd6fa0baf9'
        //ajax_wrapper('GET',url, {}, this.find_location_callback)

        const requestOptions = {
            method: "GET",
            redirect: "follow"
          };
          
        fetch("https://api.ipgeolocation.io/ipgeo?apiKey=7852dde0bf2245f6aeafc1cd6fa0baf9", requestOptions)
        .then((response) => response.text())
        .then((result) => this.find_location_callback(JSON.parse(result)))
        .catch((error) => console.error(error));

    }

    find_location_callback(data) {
        
        //console.log("location", data)
        console.log("Country Code", data['country_code3'] )
        console.log("State Code", data['state_prov'] )
        if (data['country_code3'] == 'USA') {
            console.log("here")
            this.setState({state: data['state_prov']})
        }
    }

    next_step() {
        //window.gtag('event', 'next_step', {'step_count': this.state.step});
        this.setState({step: this.state.step + 1})
    }

    prev_step() {
        this.setState({step: this.state.step - 1})
    }

    submit(data, callback) {
        if (this.state.form_submission) {
            data['id'] = this.state.form_submission.id;
        }
        ajax_wrapper('POST', '/api/createformsubmission/', data, this.submit_callback)
        this.next_step();
        callback();
    }

    submit_callback(data) {
        console.log("data", data);
        this.setState(data)

        if (data.form_submission.email) {
            this.setState({loaded: false})
            this.final_submit(data.form_submission)
        }
    }

    final_submit(state) {
        let data = {
            email: state['email'],
            password: 'lowermytaxes',
            property_id: localStorage.getItem('property_id'),
        };
        data.email = data.email.toLowerCase();
        //console.log("data", data)

        ajax_wrapper('POST', '/user/register/', data, this.login_callback);
    }

    login_callback(value) {
        if ('error' in value) {
            if (value.error === 'Invalid Credentials') {
                this.setState({
                    error: (
                        <p>
                            Wrong Email or Password. If this is your first time
                            logging in, you may need to{' '}
                            <a href="/password_reset_request/">
                                reset your password first.
                            </a>
                        </p>
                    ),
                });
            } else {
                this.setState({ error: value.error });
            }
        } else {
            localStorage.setItem('token', value.access);
            localStorage.setItem('refresh_token', value.refresh);
            localStorage.setItem('token_time', new Date());

            if (localStorage.getItem('redirect')) {
                let url = localStorage.getItem('redirect');
                localStorage.removeItem('redirect');
                window.location.href = url;
            } else {
                window.location.href = '/welcome/';
            }
        }
    }

    mapHandler(event) {
        console.log(event.target.dataset.name);
      };


    render() {

        let ages = [[<TextInput name='age' key='age' label='How old are you?' />]]
        let veteran_label = 'Are you a veteran?'
        let disability_label = 'Do you claim any other disability benefits?'

        if (this.state.data.married == 'married') {
            let veteran_label = 'Are you a veteran or is your spouse a veteran?'
            ages = [[<TextInput name='age' key='age' label='How old are you?' />], [<TextInput name='spouse_age' key='spouse_age' label='How old is your spouse?' />]]
            disability_label = 'Do you or your spouse claim any other disability benefits?';
        }

        else if (this.state.data.married == 'widow') {
            let veteran_label = 'Are you a veteran or was your spouse a veteran?'
            ages = [[<TextInput name='age' key='age' label='How old are you?' />], [<TextInput name='spouse_age' key='spouse_age' label='How old was your spouse?' />]]
        }


        let form_questions_dict = {
            0: [<TextInput name='address' key='address' label="What's your zipcode?" placeholder={'10001'} />],
            1: [<Select name='primary_residence' key='pr' label='Is this your primary residence?' options={[{'text': 'Yes','value': true},{'text':'No', 'value':false}]}/>,
            <TextInput name='years_owned' key='years_owned' label='Home many years have you owned this home?' />],
            2: [<Select name='married' key='married' label='Are you married or a widow/widower?' options={[{'text': "I'm Married",'value': 'married'}, {'text':'I am a widow/widower', 'value':'widow'}, {'text':'No', 'value':'no'}]}/>],
            3: ages,
            4: [<Select name='veteran' key='veteran' label={veteran_label} options={[{'text': 'Yes','value': true}, {'text':'No', 'value':false}]}/>],
            5: [<Select name='disability' key='disability' label={disability_label} options={[{'text': 'Yes','value': true}, {'text':'No', 'value':false}]}/>],
            6: [<TextInput required={true} name='email' key='email' label='Your email' />, <TextInput name='phone' key='phone' label='Phone Number' />,],
        }               

        let questions = form_questions_dict[this.state.step];
        

        let sell_language = null;
        let form_class = 'col-md-12';
        let exemptions_title = 'Which Exemptions Require This Information';
        if (this.state.step == 0) {
            exemptions_title = 'Common Property Tax Exemptions for Retirees';
            sell_language = <div className="col-md-6 mb-4">
                <h2 className="display-5 fw-bold text-dark mb-4">
                Save Up To $2400 On Property Taxes
                </h2>
                <p className="lead text-muted mb-4">
                We help retirees uncover hidden tax exemptions and automatically fill out all the forms to reduce their property tax burden.
                </p>
                <div className="mb-4">
                <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-check-circle-fill text-success me-2"></i>
                    <span>Free Tax Savings Assessment</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-check-circle-fill text-success me-2"></i>
                    <span>Personalized Tax Reduction Strategies</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-check-circle-fill text-success me-2"></i>
                    <span>No Upfront Costs</span>
                </div>
                </div>
            </div>;

            form_class = 'col-md-6';
        }

        let form_title = <h3 className="card-title h4 mb-4">
        Calculate Your Potential Savings (Step {this.state.step + 1}/6)
        </h3>;
        if (this.state.state) {
            form_title = <div>
                <h4>Looks like you are in {this.state.state}, you could save $<Savings state={this.state.state} /></h4>
                <h3 className="card-title h4 mb-4">
                Calculate Your Potential Savings (Step {this.state.step + 1}/6)
                </h3>
            </div>;
        }

        
        let homestead = <div className="accordion-item">
            <h2 className="accordion-header" id="homesteadHeader">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#homesteadCollapse">
                Homestead Exemption
            </button>
            </h2>
            <div id="homesteadCollapse" className="accordion-collapse collapse show" data-bs-parent="#exemptionsAccordion">
            <div className="accordion-body">
                Reduces the taxable value of your primary residence. These exemptions can save homeowners hundreds to thousands of dollars annually and are determined by your income, and how long the home has been your primary residence.
            </div>
            </div>
        </div>;
        
        let senior = <div className="accordion-item">
            <h2 className="accordion-header" id="seniorHeader">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#seniorCollapse">
                Senior Citizen Exemption
            </button>
            </h2>
            <div id="seniorCollapse" className="accordion-collapse collapse" data-bs-parent="#exemptionsAccordion">
            <div className="accordion-body">
                There are discounts, typically in the $100s of dollars for homeowners who are 65 or older. Sometimes these exemptions take your income into account as well.
            </div>
            </div>
        </div>;
        let freeze = <div className="accordion-item">
            <h2 className="accordion-header" id="seniorHeader">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#freezeCollapse">
                Property Tax Freezes
            </button>
            </h2>
            <div id="freezeCollapse" className="accordion-collapse collapse" data-bs-parent="#exemptionsAccordion">
            <div className="accordion-body">
                These types of exemptions allow you to freeze your property tax for retirement, so that your taxes will never increase as long as you own the home.
            </div>
            </div>
        </div>;
        let deferral = <div className="accordion-item">
            <h2 className="accordion-header" id="seniorHeader">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#deferralCollapse">
                Property Tax Deferrals
            </button>
            </h2>
            <div id="deferralCollapse" className="accordion-collapse collapse" data-bs-parent="#exemptionsAccordion">
            <div className="accordion-body">
                These exemptions allow you to not pay any property taxes until you either pass away or you sell the house.
            </div>
            </div>
        </div>;
        let disability = <div className="accordion-item">
            <h2 className="accordion-header" id="disabilityHeader">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#disabilityCollapse">
                Disability Exemption
            </button>
            </h2>
            <div id="disabilityCollapse" className="accordion-collapse collapse" data-bs-parent="#exemptionsAccordion">
            <div className="accordion-body">
                Tax relief for homeowners with disabilities. These can save you $1000s of dollars per year or even exempt you from all property taxes.
            </div>
            </div>
        </div>;
        let veteran = <div className="accordion-item">
            <h2 className="accordion-header" id="veteranHeader">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#veteranCollapse">
                Veteran Exemption
            </button>
            </h2>
            <div id="veteranCollapse" className="accordion-collapse collapse" data-bs-parent="#exemptionsAccordion">
            <div className="accordion-body">
                Special property tax breaks for veterans. These are sometimes $100s of dollars per year up to total exemptions.
            </div>
            </div>
        </div>;

        let submit_text = "Take The Next Step To Lowering Your Property Taxes";
        let exemption_types = null;
        let accordion_items = [];
        if (this.state.step == 0) {
            accordion_items = [homestead, senior, freeze, deferral, disability, veteran]
        }
        else if (this.state.step == 1) {
            accordion_items = [homestead]
        }
        else if (this.state.step == 2) {
            accordion_items = [senior, freeze, deferral, disability, veteran]
        }
        else if (this.state.step == 3) {
            accordion_items = [senior, freeze, deferral]
        }
        else if (this.state.step == 4) {    
            accordion_items = [veteran]
        }
        else if (this.state.step == 5) {
            accordion_items = [disability]
        }
        else if (this.state.step == 6) {
            submit_text = 'Lower My Property Taxes!'
            form_title = <h3 className="card-title h4 mb-4">
                            How Can We Get In Touch?
                            </h3>
        }

        if (this.state.step != 6) {
            
            exemption_types = <div className="card shadow-lg">
                <div className="card-body">
                    <h3 className="card-title h4 mb-4">
                    {exemptions_title}
                    </h3>
                    <div className="accordion" id="exemptionsAccordion">
                        {accordion_items}
                    </div>
                </div>
            </div>;
        }

        if (this.state.step == 7) {
            return (
                <div className="min-vh-100 d-flex flex-column">
                <main className="container flex-grow-1 py-5">
                    <div className="row align-items-center">
                        <h1>Processing Your Information...</h1>
                    </div>
                </main>
                </div>
            )
        }
        else {



            return (
                <Loading loaded={this.state.loaded}>
                <div className="min-vh-100 d-flex flex-column">
                
                
                    <main className="container flex-grow-1 py-5">
                        <div className="row align-items-center">
                            <div style={{display:'flex', justifyContent:'center'}} align='center'>                        
                                <iframe title="Map of Property Tax Exemptions In The US" aria-label="Map" id="datawrapper-chart-F4xf5" 
                                src="https://datawrapper.dwcdn.net/F4xf5/1/" scrolling="no" frameborder="0" 
                                style={{border: 'none', display:'block'}} width="600" height="439" data-external="1"></iframe>
                            </div>

                            {sell_language}

                            <div className={form_class}>
                                <div className="card shadow-lg">
                                <div className="card-body">
                                    {form_title}
                                    <div className="mb-3">
                                        <Form key={this.state.step} submit={this.submit} submit_text={submit_text} submit_button_type={'success'}>
                                            {questions}
                                        </Form>
                                    </div>
                                    
                                </div>
                                </div>
                            </div>
                        </div>

                        <div style={{height:'50px'}}></div>
                        

                        {exemption_types}
                        
                            
                    </main>

                </div>
                </Loading>
            );
        }
    }
    
}
