import React, { Component } from "react";

class AddressPicker extends Component {
  constructor(props) {
      super(props);

      this.state = {
          backgroundColor: 'white',
          textColor: '#666',
      };
  }

  render() {
      let property = this.props.property;

      let style = { cursor: 'pointer', padding: '5px' };

        let display_address = property.description;

        return (
            <div
                onClick={() =>
                    this.props.select_property(this.props.property)
                }
                className="google-address-result"
                style={style}
            >
                {display_address}
            </div>
        );
      
  }
}

export default class PlacesSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      places: [],
      error: null,
    };
    this.autocompleteService = null;
    this.autocompleteSessionToken = null;

    this.select_property = this.select_property.bind(this);
  }

  componentDidMount() {
    if (!window.google || !window.google.maps) {
      this.setState({ error: "Google Maps JavaScript API not loaded" });
      return;
    }

    this.autocompleteService = new window.google.maps.places.AutocompleteService();
    this.autocompleteSessionToken = new window.google.maps.places.AutocompleteSessionToken();
  }

  handleInputChange = (event) => {
    const query = event.target.value;
    this.setState({ query });

    if (query.length > 0) {
      this.fetchPlaces(query);
    } else {
      this.setState({ places: [] });
    }
  };

  fetchPlaces(query) {
    if (!this.autocompleteService) {
      this.setState({ error: "Autocomplete service not initialized" });
      return;
    }

    const request = {
      input: query,
      types: ["address"], // Focus on residential addresses
      componentRestrictions: { country: "us" },
    };

    this.autocompleteService.getPlacePredictions(
      request,
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          this.setState({ places: predictions });
        } else {
          this.setState({ places: [] });
        }
      }
    );
  }

  select_property(property) {
    let address = property.structured_formatting.main_text;
    let secondary_text = property.structured_formatting.secondary_text.split(',')
    let city = secondary_text[0]
    let state = secondary_text[1]
    this.props.select_address(address, city, state, property.place_id)
    this.setState({places: []})
  }

  render() {
    const { query, places, error } = this.state;

    let places_list = []
    for (let place of places) {
      places_list.push(<AddressPicker property={place} select_property={this.select_property} />)
    }

    return (
      <div>
        <input
          type="text"
          value={query}
          onChange={this.handleInputChange}
          placeholder="Search for address"
          style={this.props.style || {}}
          className='form-control'
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
        <div style={{ position: 'relative' }}>
            <div className="google-address-result-container">
                {places_list}
            </div>
        </div>
      </div>
    );
  }
}
