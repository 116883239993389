import React, { Component } from 'react';
import { Button } from 'library';

export default class State extends Component {
    constructor(props) {
        super(props);

        this.yes = this.yes.bind(this);
        this.no = this.no.bind(this);

    }

    yes() {
        this.props.submit(this.props.question.name, this.props.question.value, true)
    }

    no() {
        this.props.submit(this.props.question.name, this.props.question.value, false)
    }

    render() {
        return (
            <div>
                <p>Have you owned your home for {this.props.question.value} years or more?</p>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <Button onClick={this.yes} type={'success'} style={{width:'30%'}}>Yes</Button>
                    <Button onClick={this.no} type={'success'} style={{width:'30%'}}>No</Button>
                </div>
            </div>
        )
    }
}
