import { TextInput, Select, Form, Loading, Button } from 'library';
import React, { Component } from 'react';
import {ajax_wrapper} from 'functions';
import { GooglePlacesSearch, DateOfBirth, SignaturePad } from "pages";

export default class ExemptionQualifier extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            data: {},
            form_submission: null,
            loaded: true,
            state: null,
            fill_out_form: false,
            place_id: '',
        }

        this.submit_email = this.submit_email.bind(this);
        this.submit_form = this.submit_form.bind(this);
        this.select_address = this.select_address.bind(this);
        this.set_form_state = this.set_form_state.bind(this);
    }

    set_form_state(name, state) {
        console.log("Set Form State")
        let data = this.state.data;
        for (var index in state) {
            data[index] = state[index];
        }
        this.setState({data: data});
    }

    submit_email(form_data) {
        let data = this.state.data;
        data['email'] = form_data['email'];
        this.setState({data:data})
    }

    submit_form() {
        let data = this.state.data;        
        this.props.form_ready(data)
    }

    select_address(address, city, state, place_id) {
        let data = this.state.data;
        data['address'] = address;
        data['city'] = city;
        data['state'] = state;
        data['place_id'] = place_id;
        this.setState({data: data, place_id:place_id})
    }


    render() {

        //alaska has a bunch of extra questions, but doesn't ask for SSN, but does want an extra document for proof of age.
        //https://www.muni.org/Departments/finance/property_appraisal/Forms_Applic/Documents/2025%20Senior%20Exemption%20app%20updated.pdf

        //ct wants a tax return if you've already filed one
        //https://portal.ct.gov/-/media/opm/igpp-data-grants-mgmt/igpp-forms/m-35h-owners-application.pdf?rev=bf6f364c95b54d929f3894b7e9492f8f&hash=A35C7E990696E7459AA8AECE440D08C4


        if (!this.state.data.email) {
            return (
                <div className="min-vh-100 d-flex flex-column">
            
                    <main className="container flex-grow-1 py-5">
                        <div className="row align-items-center">
                            <h2>You qualify for up to ${this.props.exemption_data.max_savings} in savings every year!</h2>
                            <Form key='email' submit={this.submit_email}>
                                <TextInput label='Enter your email below to get started on the application.' name='email' />
                            </Form>
                        </div>
                    </main>
                </div>
            )
        }
        else if (this.state.complete) {
            return (
                <div className="min-vh-100 d-flex flex-column">
            
                    <main className="container flex-grow-1 py-5">
                        <div className="row align-items-center">
                            <h2>We are processing your form and will email you when </h2>
                        </div>
                    </main>
                </div>
            )
        }
        else {
            return (
                <div className="min-vh-100 d-flex flex-column">
            
                    <main className="container flex-grow-1 py-5">
                        <div className="row align-items-center">
                            <h2>Only a few form fields away from saving ${this.props.exemption_data.max_savings} every year for as long as you own your home!</h2>
                            <p>This next step is going to get a bit personal, but don't worry, we will take care of your data. We've got multiple layers of encryption and security to keep it safe.</p>
                            
                            <h4>Details About Your Property</h4>
                            <GooglePlacesSearch select_address={this.select_address} />
                            
                            <Form row={true} defaults={this.state.data} key={this.state.place_id} global_state_name='address' set_global_state={this.set_form_state} auto_set_global_state={true}>
                                <TextInput label='Your Address' name='address' className='col-md-6 col-sm-12' />
                                <TextInput label='Your Appartment Number (Optional)' name='address2' className='col-md-6 col-sm-12' />
                                <TextInput label='City' name='city' className='col-4' />
                                <TextInput label='State' name='state' className='col-4' />
                                <TextInput label='Zip' name='zipcode' className='col-4' />
                            </Form>

                            <div style={{height:'50px'}}></div>
                            <h4>Details About You</h4>
                            
                            <Form row={true} global_state_name='personal_details' set_global_state={this.set_form_state} auto_set_global_state={true}>
                                <TextInput label='First Name' name='first_name' className='col-6' />
                                <TextInput label='Last Name' name='last_name' className='col-6' />
                                <DateOfBirth name='dob' className='col-12' label={"What's your birthday?"} />
                                <TextInput type='password' label='Social Security Number' name='ssn' className='col-md-4 col-sm-12' />
                            </Form>
                            
                            <div style={{height:'50px'}}></div>
                            <h4>Details About Your Spouse (if they live with you)</h4>
                            
                            <Form row={true} global_state_name='spouse_details' set_global_state={this.set_form_state} auto_set_global_state={true}>
                                <TextInput label='First Name' name='spouse_first_name' className='col-6' />
                                <TextInput label='Last Name' name='spouse_last_name' className='col-6' />
                                <DateOfBirth name='spouse_dob' className='col-12' label={"What's their birthday?"}/>
                                <TextInput type='password' label='Social Security Number' name='spouse_ssn' className='col-md-4 col-sm-12' />
                            </Form>
                            
                            <div style={{height:'50px'}}></div>
                            <h4>Finally, your signature.</h4>
                            <SignaturePad onClose={this.set_form_state} name='signature' />

                            <div style={{height:'50px'}}></div>
                            <Button type='success' onClick={this.submit_form}>Claim Your Exemption</Button>
                        </div>
                    </main>
                </div>
            )
        }
        
            
    }
    
}
