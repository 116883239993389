import React, { Component } from 'react';
import { ajax_wrapper } from 'functions';
import { Loading, Container, Button, Form, TextInput, Alert } from 'library';
import { Address, AssessedValue, ComparableCard } from 'pages';
import {
    APIProvider,
    Map,
    Marker,
    InfoWindow,
} from '@vis.gl/react-google-maps';
import {Helmet} from "react-helmet";

class AddressPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundColor: 'white',
            textColor: '#666',
        };
    }

    render() {
        let property = this.props.property;

        let style = { cursor: 'pointer', padding: '5px' };

        if (property.address == 'None Found') {
            return (
                <div className="google-address-result" style={style}>
                    No properties found. Which means you're paying the right
                    amount for your property tax! Keep it that way with instant
                    assessment alerts. <a href="/sign_up">Sign up today</a>
                </div>
            );
        } else {
            let display_address = property.address;

            return (
                <div
                    onClick={() =>
                        this.props.select_property(this.props.property)
                    }
                    className="google-address-result"
                    style={style}
                >
                    {display_address}
                </div>
            );
        }
    }
}


export default class AddressSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            potential_addresses: [],
            loaded: true,
            windowWidth: 0,
            windowHeight: 0,
            show_potential_address: true,
        };

        this.search_address = this.search_address.bind(this);
        this.load_addresses = this.load_addresses.bind(this);
        this.select_property = this.select_property.bind(this);

        this.focus_input = this.focus_input.bind(this);
        this.blur_input = this.blur_input.bind(this);
    }

    search_address(name, data) {
        if (data['address'] && data['address'].length > 2) {
            ajax_wrapper('GET','/api/address_search/', data, this.load_addresses)
        }
    }

    load_addresses(data) {
        console.log("Load Addresses", data['data'])
        this.setState({potential_addresses: data['data']})
    }

    select_property(property) {
        window.location.href = '/property_opportunities/' + property.id
    }

    focus_input() {
        this.setState({ show_potential_address: true });
    }

    blur_input() {
        //this.setState({show_potential_address: false})
    }

    render() {


        let addresses = [];
        if (this.state.show_potential_address) {
            console.log("Potential Addresses", this.state.potential_addresses)
            for (let property of this.state.potential_addresses) {
                addresses.push(
                    <AddressPicker
                        property={property}
                        select_property={this.select_property}
                    />,
                );
            }
        }

        return (
            <Container>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Save Money On Your Property Taxes and Bills</title>
                    <meta name="description" content="An easy way to find where you are overpaying on property expenses." />
                </Helmet>

                <Loading loaded={this.state.loaded}>
                    <div className="simple-card">
                        <h1>Are you overpaying on your property taxes? Find out today!</h1>
                        
                        <br />
                        
                        <Form
                            auto_set_global_state={true}
                            set_global_state={this.search_address}
                            global_state_name="address_search"
                        >
                            <h5>Search Your Address To Find Out If You Are Overpaying</h5>
                            <TextInput
                                name="address"
                                autocomplete={'off'}
                            />

                            <div style={{ position: 'relative' }}>
                                <div className="google-address-result-container">
                                    {addresses}
                                </div>
                            </div>
                        </Form>
                           
                    </div>
                </Loading>
            </Container>
        );
    }
}
