import { TextInput, Select, Form, Loading, Button, Container } from 'library';
import React, { Component } from 'react';
import {ajax_wrapper} from 'functions';
import { ExemptionQualifier, ExemptionForm} from 'pages';

//import {Elements} from '@stripe/react-stripe-js';
//import {loadStripe} from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');


export default class Retiree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form_submissions: [],
            loaded: false,
        }

        this.get_submissions = this.get_submissions.bind(this);
        this.get_submissions_callback = this.get_submissions_callback.bind(this);
    }

    componentDidMount() {
        this.get_submissions();
    }

    get_submissions() {
        ajax_wrapper('GET','/api/viewformsubmissions/', {}, this.get_submissions_callback)
    }

    get_submissions_callback(data) {
        data['loaded'] = true;
        this.setState(data);
    }


    render() {

            let form_submissions = [];
            for (var submission of this.state.form_submissions) {
                let data = submission.data;
                let email = 'None'
                if (data['email']) {
                    email = data['email'];
                }
                form_submissions.push(<tr>
                    <td>{submission.created_at}</td>
                    <td>{email}</td>
                    <td style={{wordWrap:'break-word'}}>{JSON.stringify(data)}</td>
                    </tr>)
            }

            return (
                <Loading loaded={this.state.loaded}>
                    <Container>
                        <table className='table' style={{maxWidth:'100%'}}>
                            <thead>
                                <tr>
                                    <th>Created At</th>
                                    <th>Email</th>
                                    <th>Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                {form_submissions}
                            </tbody>
                        </table>
                    </Container>
                </Loading>
            )
        
    }
    
}
