import React, { Component } from 'react';
import { Button, Select, TextInput, Form } from 'library';

export default class Birthday extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'birth_day': null,
            'birth_month': null,
            'birth_year': null,
        }

        this.set_global_state = this.set_global_state.bind(this);
    }

    set_global_state(name, data) {
        this.setState(data, this.set_form_state);
        console.log(name, data);
    }

    set_form_state() {
        if (this.state.birth_day && this.state.birth_month && this.state.birth_year) {
            let form_state = {}
            form_state[this.props.name] = `${this.state.birth_year}-${this.state.birth_month}-${this.state.birth_day}`
            this.props.set_form_state(form_state)
        }
    }

    render() {
        let months = [
            {text:'January',value: 1},
            {text:'February',value: 2},
            {text:'March',value: 3},
            {text:'April',value: 4},
            {text:'May',value: 5},
            {text:'June',value: 6},
            {text:'July',value: 7},
            {text:'August',value: 8},
            {text:'September',value: 9},
            {text:'October',value: 10},
            {text:'November',value: 11},
            {text:'December',value: 12},
        ]

        return (
            <div>
                <p>{this.props.label}</p>
                <Form row={true} auto_set_global_state={true} global_state_name='birthday' set_global_state={this.set_global_state}>
                    <Select name='birth_month' options={months} className='col-4' placeholder='Month' />
                    <TextInput type='number' name='birth_day' placeholder='Day' className='col-4' />
                    <TextInput type='number' name='birth_year' placeholder='Year' className='col-4' />
                </Form>
            </div>
        )
    }
}
