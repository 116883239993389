import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import { Loading, Container, Button } from 'library';
import { Address, ComparableCard } from 'pages';
import {Helmet} from "react-helmet";
import { DOLLAR_STYLING } from 'constants';

class ComparableRow extends Component {

    render() {
        let property = this.props.property;
        let comparable = this.props.comparable;

        let comparable_dollar_per_sqft = comparable.taxInfo.assessedImprovementValue/comparable.propertyInfo.livingSquareFeet
        let comparable_dollar_per_acre = comparable.taxInfo.assessedLandValue/(comparable.lotInfo.lotSquareFeet/43560)

        let savings = (property.taxInfo.assessedValue - comparable_dollar_per_sqft * property.propertyInfo.livingSquareFeet - comparable_dollar_per_acre * property.lotInfo.lotSquareFeet/43560) * .0045

        return (
            <tr>
                <td><a href={'/property_opportunities/' + comparable.id} target='_blank' > {comparable.propertyInfo.address.label}</a></td>
                <td>{comparable.propertyInfo.livingSquareFeet}</td>
                <td>{comparable_dollar_per_sqft.toLocaleString('en-US', DOLLAR_STYLING)}</td>
                <td>{(comparable.lotInfo.lotSquareFeet/43560).toFixed(2)}</td>
                <td>{comparable_dollar_per_acre.toLocaleString('en-US', DOLLAR_STYLING)}</td>
                <td>{savings.toLocaleString('en-US', DOLLAR_STYLING)}</td>
            </tr>
                

        );
    }
}

class AssessedValue extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let property = this.props.property;

        return (
            <div>
                <h4>Property Tax Details</h4>
                <table className="table">
                    <tbody>
                        <tr>
                            <td><b>Building Assessed Value:</b> {property.taxInfo.assessedImprovementValue.toLocaleString('en-US', DOLLAR_STYLING)}</td>
                            <td><b>Sq. Ft.:</b> {property.propertyInfo.livingSquareFeet}</td>
                            <td><b>$/Sqft:</b>{(property.taxInfo.assessedImprovementValue/property.propertyInfo.livingSquareFeet).toLocaleString('en-US', DOLLAR_STYLING)}</td>
                        </tr>
                        <tr>
                            <td><b>Land Assessed Value:</b> {property.taxInfo.assessedLandValue.toLocaleString('en-US', DOLLAR_STYLING)}</td>
                            <td><b>Acreage:</b> {(property.lotInfo.lotSquareFeet/43560).toFixed(2)}</td>
                            <td><b>$/Acre:</b> {(property.taxInfo.assessedLandValue/(property.lotInfo.lotSquareFeet/43560)).toLocaleString('en-US', DOLLAR_STYLING)}</td>
                        </tr>
                        <tr>
                            <td><b>Total Assessed Value:</b> {property.taxInfo.assessedValue.toLocaleString('en-US', DOLLAR_STYLING)}</td>
                            <td><b>Yearly Taxes:</b> {parseFloat(property.taxInfo.taxAmount).toLocaleString('en-US', DOLLAR_STYLING)}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>

        );
    }
}

export default class Property extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'property': {},
            'comparables': [],
            loaded: false,
        }

        this.get_property = this.get_property.bind(this);
        this.load_property = this.load_property.bind(this);
    }

    componentDidMount() {
        this.get_property();
    }

    get_property() {
        let params = get_global_state()['params']
        localStorage.setItem('property_id', params['property_id']);
        ajax_wrapper('GET', '/api/property_opportunities/', {'property_id': params['property_id']}, this.load_property)
    }

    load_property(data) {
        data['loaded'] = true;
        this.setState(data)
    }

    render() {
        let comparable_list = []
        for (let comparable of this.state.comparables) {
            comparable_list.push(<ComparableRow comparable={comparable} property={this.state.property} />)
        }

        if (this.state.loaded) {

            console.log("State", this.state)
            let property = this.state.property;
            console.log("Property", property)
            let address = property.propertyInfo.address;
            
            let latlng = property.propertyInfo.latitude + ',' + property.propertyInfo.longitude;
            let img_url =
            'https://maps.googleapis.com/maps/api/streetview?location=' +
            latlng +
            '&size=600x400&key=AIzaSyCTeyTeeIoM-qDCe1Z8W7OK0qDVAJ8QzhU';


            return (
                <Container>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{address.label} Property Tax Comparison</title>
                        <meta name="description" content={"A comparison of " + address.label + " and similar properties and their property tax rates."} />
                    </Helmet>
                    <Loading loaded={this.state.loaded}>
                        <div
                            style={{
                                margin: '-10px -20px 0px -20px',
                                position: 'relative',
                            }}
                        >
                            <div
                                className="background-image"
                                style={{
                                    backgroundImage: `url(${img_url})`,
                                }}
                            ></div>
                            <div className="classy-address">
                                <h4>{address.label}</h4>
                            </div>
                        </div>

                        <div style={{height:'50px'}}></div>
                        <AssessedValue property={this.state.property} />
                        <div style={{height:'50px'}}></div>
                        <h4>Comparables</h4>
                        <p>These are similar houses in your area to see if you have been taxed unfairly.</p>
                        <Button type='success' href='/sign_up'>Help Me Lower My Taxes</Button>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Address</th>
                                    <th>Sqft</th>
                                    <th>Assessed $/Sqft</th>
                                    <th>Acreage</th>
                                    <th>Assessed $/Acre</th>
                                    <th>Taxes Saved If You Were Taxed The Same Way</th>
                                </tr>
                            </thead>
                            <tbody>
                                {comparable_list}
                            </tbody>
                        </table>
                        
                        <div style={{height:'20px'}}></div>
                        <h4>Exemptions</h4>
                        <p>The following are property exemptions within Colorado that you may qualify for.</p>
                        
                        <div style={{height:'20px'}}></div>
                        <h4>Other Ways To Save</h4>
                        <p>The following are other ways to save money on your yearly home expenses.</p>
                        
                    </Loading>
                </Container>

            );
        }
        else {
            return (<Loading loaded={this.state.loaded} />)
        }
    }
}
