import React, { Component } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "library";

class SignaturePad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPadOpen: false,
      signatureData: null,
    };

    this.sigCanvasRef = React.createRef();

    this.openPad = this.openPad.bind(this);
    this.closePad = this.closePad.bind(this);
    this.clearPad = this.clearPad.bind(this);
  }

  openPad = () => {
    this.setState({ isPadOpen: true });
  };

  closePad = () => {
    const signaturePad = this.sigCanvasRef.current;
    let signatureData = null;

    // Check if the user signed the pad
    if (!signaturePad.isEmpty()) {
      signatureData = signaturePad.toDataURL(); // Get the base64 image
    }

    this.setState({ isPadOpen: false, signatureData }, () => {
      // Pass the signature data to a parent component or handle it
      if (this.props.onClose) {
        this.props.onClose(this.props.name, {'signature': signatureData});
      }
    });
  };

  clearPad = () => {
    this.sigCanvasRef.current.clear();
  };

  render() {
    const { isPadOpen, signatureData } = this.state;

    return (
      <div>
        <Button type='primary' onClick={this.openPad}>Open Signature Pad</Button>
        {!isPadOpen && signatureData && (<img src={signatureData} style={{display:'block', width:'1200px', height:'200px'}} />)}

        {isPadOpen && (
          <div className="signature-modal">
            <div className="signature-pad-container">
                <div style={{border:'1px solid black'}}>
              <SignatureCanvas
                ref={this.sigCanvasRef}
                penColor="black"
                canvasProps={{
                  width: 1200,
                  height: 200,
                  className: "signature-canvas",
                }}
                backgroundColor="white"
              />
              </div>
              <div className="signature-pad-controls">
                <Button type='danger' onClick={this.clearPad}>Clear</Button>
                <Button type='primary' onClick={this.closePad}>Done</Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SignaturePad;