import { Container } from 'library';
import React, { Component } from 'react';

export default class Home extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        return (
            <Container>
                <h1>Thanks for joining! We just sent you an email, check for it (in your spam folder too) so we can start the process and save you money.</h1>
            </Container>
        );
    }
}
