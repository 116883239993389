import { TextInput, Select, Form, Loading, Button, Container } from 'library';
import React, { Component } from 'react';
import {ajax_wrapper} from 'functions';
import { ExemptionQualifier, ExemptionForm} from 'pages';

//import {Elements} from '@stripe/react-stripe-js';
//import {loadStripe} from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');


export default class ViewFunnels extends Component {
    constructor(props) {
        super(props);

        this.state = {
            funnels: [],
            loaded: false,
        }

        this.get_funnels = this.get_funnels.bind(this);
        this.get_funnel_callbacks = this.get_funnel_callbacks.bind(this);
    }

    componentDidMount() {
        this.get_funnels();
    }

    get_funnels() {
        ajax_wrapper('GET','/api/funnelperformance/', {}, this.get_funnel_callbacks)
    }

    get_funnel_callbacks(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    render() {

        let funnels = [];
        for (var funnel of this.state.funnels) {
            let funnel_rows = []
            for (let row of funnel.rows) {
                funnel_rows.push(<tr>
                    <td>{row.step}</td>
                    <td>{row.count}</td>
                </tr>)
            }


            let funnel_display = <div>
                <p>{funnel.url}</p>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Steps</th>
                            <th>Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {funnel_rows}
                    </tbody>
                </table>
            </div>

            funnels.push(funnel_display)
            funnels.push(<div style={{height:'50px'}}></div>)

        }

        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    {funnels}
                </Container>
            </Loading>
        )
        
    }
    
}
