import { TextInput, Select, Form, Loading, Button } from 'library';
import React, { Component } from 'react';
import {ajax_wrapper} from 'functions';
import { ExemptionQualifier, ExemptionForm} from 'pages';

//import {Elements} from '@stripe/react-stripe-js';
//import {loadStripe} from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');


export default class ExemptionFunnel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            data: {},
            form_submission: null,
            loaded: true,
            state: null,
            qualified: null,
            form_ready: false,
            exemption: {},
            funnel_visit: null,
        }

        this.qualified = this.qualified.bind(this);
        this.form_ready = this.form_ready.bind(this);
        this.submit = this.submit.bind(this);
        this.submit_callback = this.submit_callback.bind(this);
        this.visitor_callback = this.visitor_callback.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem('visitor_id')) {
            this.setState({funnel_visit: {'id': localStorage.getItem('visitor_id')}})
        }
        else {
            ajax_wrapper('POST', '/api/createvisitor/', {'url': window.location.pathname, 'referrer': document.referrer}, this.visitor_callback)
        }
    }

    visitor_callback(data) {
        console.log("Callback", data)
        localStorage.setItem('visitor_id', data['funnel_visit'].id)
        this.setState(data)
    }

    qualified(data, qualified) {
        this.setState({qualified: qualified, data:data});
    }

    form_ready(form_data) {
        let data = this.state.data;
        for (var key in form_data) {
            data[key] = form_data[key];
        }
        this.submit(data, console.log)
        this.setState({form_ready: true})
    }

    submit(data, callback) {
        if (this.state.form_submission) {
            data['id'] = this.state.form_submission.id;
        }
        data['funnel_visit'] = this.state.funnel_visit.id
        console.log("Submit", data)
        ajax_wrapper('POST', '/api/createformsubmission/', data, this.submit_callback)
        callback();
    }

    submit_callback(data) {
        this.setState({form_submission: data['form_submission']})
    }

    render() {

        let stripe_key = 'pk_test_51QHn8ORx9MNACnOxqWT7fZjBGxqWLuDhqfxU1VsEXpWBDh0wu0l23htByF0G44WzW3lyO9AvcMGURTvDeesZm9nO00cJHwAS6c'
        //let stripe_key = 'pk_live_51QHn8ORx9MNACnOxdVe1OGIbtfumjpnFhJVjqigs0aveTYGstt63ZVSCzsfFTPhFesQhh9eHFc0MzYoD3HebGy2F00bWX5iVze'

        let exemption_data = {}
        if (window.location.pathname == '/colorado_senior_exemption') {
            exemption_data = {
                questions: [
                    {name: 'state', value: 'Colorado'},
                    {name: 'age', value: '65'},
                    {name: 'home_ownership', value: '10'},
                ],
                max_savings: 480,
                name: 'The Colorado Homestead Exemption',
            }
        }
        else if (window.location.pathname == '/alaska_senior_exemption') {
            exemption_data = {
                questions: [
                    {name: 'state', value: 'Alaska'},
                    {name: 'age', value: '65'},
                ],
                max_savings: 1500,
                name: 'The Alaskan Seniors Exemption',
            }
        }

        if (this.state.qualified == null) {
            return (<ExemptionQualifier qualified={this.qualified} submit={this.submit} exemption_data={exemption_data} />)
        }
        else if (this.state.qualified == false) {
            return (
                <div className="min-vh-100 d-flex flex-column">
            
                    <main className="container flex-grow-1 py-5">
                        <div className="row align-items-center">
                            <h2>Dang! You didn't qualify, but maybe we can help another way.</h2>
                            <p></p>
                            <Form submit={this.submit}>
                                <TextInput name='email' label='Add your email below to find and be notified about personalized ways to reduce your property taxes and other home expenses.' />
                            </Form>
                        </div>
                    </main>
                </div>
            )
        }
        else if (this.state.qualified && !this.state.form_ready) {
            return (<ExemptionForm form_ready={this.form_ready} submit={this.submit} exemption_data={exemption_data} />)
        }
        else {
            const options = {
                // passing the client secret obtained from the server
                clientSecret: '{{CLIENT_SECRET}}',
              };
            return (
                <div className="min-vh-100 d-flex flex-column">
                    <main className="container flex-grow-1 py-5">
                        <div className="row align-items-center">
                            <h3>Great Job! We'll send you an email once we have filed this for you.</h3>
                        </div>
                    </main>
                </div>
                )

                //<p>Pay $5 with stripe</p>
        }
    }
    
}
