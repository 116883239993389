import { TextInput, Select, Form, Loading, Button } from 'library';
import React, { Component } from 'react';
import {ajax_wrapper} from 'functions';
import {State, Age, HomeOwnership} from 'pages'
export default class ExemptionQualifier extends Component {
    constructor(props) {
        super(props);

        

        this.state = {
            step: 0,
            data: {},
            form_submission: null,
            loaded: true,
            state: null,
            fill_out_form: false,
            questions: this.props.exemption_data.questions,
            max_savings: this.props.exemption_data.max_savings,
            exemption_name: this.props.exemption_data.name,
        }

        //rules
        //state == Colorado
        //age >= 65
        //home_ownership >= 10

        this.button_click = this.button_click.bind(this);
        this.submit = this.submit.bind(this);
        this.submit_callback = this.submit_callback.bind(this);
        this.final_submit = this.final_submit.bind(this);
        this.login_callback = this.login_callback.bind(this);

    }

    button_click(field, question_value, value) {
        let data = this.state.data;
        data[field] = value;
        this.props.submit(data, console.log)
        this.setState({data:data, step: this.state.step + 1})

        if (this.state.step > this.state.questions.length - 2) {
            var qualified = true;
            for (var index in this.state.data) {
                if (this.state.data[index] == false) {
                    qualified = false;
                    break;
                }
            }
            this.props.qualified(data, qualified)
        }
    }

    submit(data, callback) {
        if (this.state.form_submission) {
            data['id'] = this.state.form_submission.id;
        }
        ajax_wrapper('POST', '/api/createformsubmission/', data, this.submit_callback)
        callback();
    }

    submit_callback(data) {
        console.log("data", data);
        this.setState(data)

        if (data.form_submission.email) {
            this.setState({loaded: false})
            this.final_submit(data.form_submission)
        }
    }

    final_submit(state) {
        let data = {
            email: state['email'],
            password: 'lowermytaxes',
            property_id: localStorage.getItem('property_id'),
        };
        data.email = data.email.toLowerCase();
        //console.log("data", data)

        ajax_wrapper('POST', '/user/register/', data, this.login_callback);
    }

    login_callback(value) {
        if ('error' in value) {
            if (value.error === 'Invalid Credentials') {
                this.setState({
                    error: (
                        <p>
                            Wrong Email or Password. If this is your first time
                            logging in, you may need to{' '}
                            <a href="/password_reset_request/">
                                reset your password first.
                            </a>
                        </p>
                    ),
                });
            } else {
                this.setState({ error: value.error });
            }
        } else {
            localStorage.setItem('token', value.access);
            localStorage.setItem('refresh_token', value.refresh);
            localStorage.setItem('token_time', new Date());
            this.setState({'logged_in':true})
        }
    }


    render() {

        let question_data = this.state.questions[this.state.step]
        let question = null;
        if (question_data['name'] == 'state') {
            question = <State question={question_data} submit={this.button_click} />;
        }
        else if (question_data['name'] == 'age') {
            question = <Age question={question_data} submit={this.button_click} />;
        }
        else if (question_data['name'] == 'home_ownership') {
            question = <HomeOwnership question={question_data} submit={this.button_click} />;
        }

        return (
            <Loading loaded={this.state.loaded}>
                <div className="min-vh-100 d-flex flex-column">
                
                    <main className="container flex-grow-1 py-5">
                        <div className="row align-items-center">
                            

                            <div className="col-md-6 mb-4">
                                <h2 className="display-5 fw-bold text-dark mb-4">
                                Save Up To ${this.state.max_savings} Per Year On Your Property Taxes With {this.state.exemption_name}
                                </h2>
                                <p className="lead text-muted mb-4">
                                We help people uncover hidden tax exemptions and automatically fill out all the forms to reduce their property tax burden.
                                </p>
                            </div>

                            <div className='col-md-6 col-sm-12'>
                                <div className="card shadow-lg">
                                    <div className="card-body">
                                        <h3 className="card-title h4 mb-4">
                                            See If You Qualify (Step {this.state.step + 1}/{this.state.questions.length})
                                        </h3>
                                        <div className="mb-3">
                                            
                                            {question}
                                        
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </Loading>
            );
    }
    
}
