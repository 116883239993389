import React, { Component } from 'react';

export default class Savings extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let savings_dict = {
            'Alabama': 14.4,
            'Oklahoma': 15.2,
            'Virginia': 21.6,
            'Louisiana': 38.25,
            'New Mexico': 40.2,
            'Mississippi': 52.5,
            'Iowa': 67.9,
            'Georgia': 100.8,
            'West Virginia': 110,
            'Wyoming': 143,
            'Missouri': 150,
            'Washington': 189.5,
            'Delaware': 192.,
            'Oregon': 196.6349,
            'South Carolina': 230,
            'Hawaii': 234,
            'Michigan': 249.3,
            'North Dakota': 264.9,
            'Tennessee': 292,
            'Maine': 336.,
            'Rhode Island': 336.9,
            'Illinois': 341.25,
            'Maryland': 363.2,
            'Arizona': 394,
            'Arkansas': 425,
            'Colorado': 450.,
            'New Jersey': 500,
            'Utah': 518.8,
            'North Carolina': 558,
            'Minnesota': 676.8,
            'Ohio': 679.9,
            'Indiana': 684.724,
            'Kentucky': 685.98,
            'Florida': 710,
            'Connecticut': 712,
            'Pennsylvania': 975,
            'Massachusetts': 1000,
            'Montana': 1000,
            'New Hampshire': 1096.5,
            'Wisconsin': 1168,
            'Nevada': 1200,
            'Kansas': 1253.7,
            'Vermont': 1390,
            'Nebraska': 1404,
            'California': 1421.2,
            'Idaho': 1500,
            'South Dakota': 1500,
            'Texas': 1617,
            'New York': 1679.5,
            'Alaska': 2407.5,
        }

        let savings = 0;
        if (savings_dict[this.props.state]) {
            savings = savings_dict[this.props.state].toFixed(0);
        }

        return (
            savings
        );
    }
}
